import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'
import {UserType, ConstraintDay} from './caltypes'
import './dayform.scss'
import {Line, kind2tag} from './utilline'
import {info1, get_user, get_anti_days} from './helpers'


function info({line, user, cdays}:{line: Line; user: UserType; cdays: ConstraintDay[]}){

  window.alert(info1({tag: kind2tag(line.row.kind), line, user}))

  //debug
  get_anti_days(cdays)


}

//
//　●予約についての状態をアイコンにて
//
// 1. ログインユーザーの予約したレコードである 削除できる (既存なので予約はできない)
// 2. 別のユーザーの予約レコードである 削除できない (既存なので予約はできない)
// 3. 予約はないので　削除できない、他の予約の制約を受けて 予約もできない
// 4. 既存の予約はないので、削除できない、制約もないので 予約はできる 
// 
//1
export const IconOwner = () => <Fa icon={['fas', 'user-check']} className="" />
//2
export const IconOthers = () => <Fa icon={['far', 'circle-user']} className="fa-lg" />
//3
export const IconConstraint = () => <Fa icon={['far', 'circle-xmark']} className="fa-lg" />
//4
export const IconEmpty = () => <Fa icon={['far', 'circle']} className="fa-lg" />
//

const s_ = {
  backgroundColor: "WhiteSmoke",
  
}

//
// ● formのタイトルと中止ボタン
//  2022-03-17 予約の作成または削除 (admin)      [中止]
//
// 中止ボタンはformの中では表現できないし、submit出口が２つだとwarnだ。だから　formの外へだしておくこと。
//
export const TitleCancel = ({login, ymd, title, onCancel}:
  {login: UserType; ymd:string; title: string; onCancel: ()=>void;}) => {

    
  // const login_user_disp= ()=>{
  //   let ucat= ''
  //   if(is_admin(login.username)) ucat='管理者'
  //   else if( login.danka ) ucat = '檀家様'
  //   else if( login.funeral ) ucat = '業者様'
  //   return `${login.username} ${ucat} がログインしています`
  // }
  
  
  return(
    <div className="input-group">
      <div className="form-control ellipsis" style={s_}>{ymd}{' '}{title}</div>
      <button type="button" className="btn btn-outline-primary" onClick={onCancel}>中止</button>
    </div>
  )

}
//
// ● CheckboxHeader  (説明部checkboxの)
//
export const CheckboxHeader = () => {
  return(
    <div className="input-group">

      {/* 予約済みか空きか */}
      <div className="form-control" style={s_}>
        <IconOwner />
        <IconOthers />
        予約あり
      </div>

      <div className="form-control" style={s_}>
        <IconConstraint />
        予約不可
      </div>

      <div className="form-control" style={s_}>
        <IconEmpty />
        予約可能
      </div>
   
    </div>

  )
}
//
// ●予約の有無、Ownerの状態を１つのアイコンで
//
export const StatusIcon = ({login, line}:{login: string; line: Line;}) : JSX.Element => {

  if(!line.empty){
    // まず 空きでない、なら ユーザーが付いているので 他人か自分でアイコンを変える。
    if(login === line.row.user){
      //自分の予約である
      return (<IconOwner />)
    }else{
      //他人の予約である
      return (<IconOthers />)
    }

  }else if(!line.enable){
    // 次に (空きなのに) 予約できない、つまり衝突している。
    return (<IconConstraint />)
  }else{
    // 空き、衝突なし
   return(<IconEmpty />)
  }
}
//
// window.alertで 詳細情報を確認する。
//
export const LineInfoBtn = ({line, users, cdays}:{line: Line; users: UserType[]; cdays: ConstraintDay[]}) =>{

  //
  // 「詳細」ボタンから windows ダイアログにて 情報を表示する, なるべくリアルタイムで。
  //
  const user = get_user(line.row.user, users)
//  const text= info1({tag: kind2tag(line.row.kind), line, user})

  //
  // @2024 debug
  //


  return(
    <button type="button" className="btn btn-outline-primary"
      onClick={()=>info({line, user, cdays})}>
      詳細
    </button>
  )
}
// EOF
