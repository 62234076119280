//import path from 'path'
import {addDays, format, isSameDay} from 'date-fns'
import {UserType, userNone, ConstraintDay, ReservationRow, constraintDayNone} from './caltypes'
import {Line, tag2kind, dt_tag} from './utilline'


//import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'


// inline styles
export const bg_ivory = {backgroundColor: "ivory"}
export const bg_snow = {backgroundColor: "snow"}
export const bg_beige = {backgroundColor: "beige"}
export const bg_lightgoldenrodyellow = {backgroundColor: "lightgoldenrodyellow"}

export function desktopDir(){
  const platform= process.platform // win32
  const key = platform === 'win32' ? 'USERPROFILE' : 'HOME'
  const home = process.env[key] || './' //あとでちゃんと ...
  //return path.join(home, 'Desktop') // C:/Users/sasa/Desktop
  //@2025 (環境変数ではいずれも '/' 末尾にないので ... pathを使わずに)
  return home + '/' + 'Desktop'
  return 
}
export function downloadsDir(){
  const platform= process.platform // win32
  const key = platform === 'win32' ? 'USERPROFILE' : 'HOME'
  const home = process.env[key] || './' 
  //return path.join(home, 'Downloads') // C:/Users/sasa/Downloads
  return  home + '/ ' + 'Downloads'
}

export const stamp = () :string => format(new Date(), 'yyyy-MMdd HH:mm:ss')
export const hms = () :string => format(new Date(), 'HH:mm:ss')
export const hmsS = () :string => format(new Date(), 'HH:mm:ss.SSS')

// new Date() されて serverから来たJSONの... ??ちょっと注意も必要
// date-fnsの breaking-changesとして https://date-fns.org/v2.25.0/docs/parseISO  あとで。
export const dt_hms = (dt: Date) : string => format(dt, 'HH:mm:ss')
export const dt_hmsS = (dt: Date) : string => format(dt, 'HH:mm:ss.SSS')
export const dt_stamp = (dt: Date) : string => format(dt, 'yyyy-MMdd HH:mm:ss')

// socket経由できた dt --> isoString をそのまま stackしておいて、localstorageにするので、表示はこの stamp_isoで。
export const dts_none = '1970-01-01T00:00:00Z'
export const dts_now = () => (new Date()).toISOString()
export const dts_stamp = (dts: string) => (dts && 0<dts.length)
    ? format( new Date(dts),  'yyyy-MMdd HH:mm:ss')
    : dts_none


//ざっくり
export function stringify2(o: object){
  return JSON.stringify(o).replace(/"/g, '')
}

//
// User詳細のために
//
export function get_user(name: string, users: UserType[]): UserType {
  const found: UserType | undefined = users.find( u=> u.username === name )
  const user= found ? found : userNone
  return {...user}
}
//
type Info1 = {
  tag: string;
  line: Line;
  user: UserType;
}
export function info1( {tag, line, user }: Info1 ) : string {
  const row= line.row
  const u= user
  //@2022-1110 表示変更 通夜Hなどのため @2024 追加
  const disp_kind = (k: string) =>{
    switch(k){
      case '通夜': return '葬儀'
      case '通夜H': return '法要室葬儀'
      case '通夜L': return '金蓮寺ホール葬儀'
      case '通夜A': return '安置室ミニホール'

      case '葬儀': return '葬儀'
      case '葬儀H': return '法要室葬儀'
      case '葬儀L': return '金蓮寺ホール葬儀'
      case '葬儀A': return '安置室ミニホール'

      case '安置': return '葬儀'
      case '安置H': return '法要室葬儀'
      case '安置L': return '金蓮寺ホール葬儀'
      case '安置A': return '安置室ミニホール'
      default: return k
    }
  }

  const msg_base=`\
------------------------------------------
予約日: ${row.date}
種別: ${disp_kind(row.kind)}
予約: ${line.empty ? `予約がありません` : `すでに予約が存在します`}
予約者: ${u.username} ${u.kname} ${u.mail} ${u.addr1}
状態: ${line.enable ? `予約できます` : `予約できません`}
制約: ${line.reason}
(link): ${line.row.link}
(option): ${line.option}
(id): ${line.row.id < 0 ? 'なし' : line.row.id}
`
  //
  //
  //
  const msg_sogi=`\
------------------------------------------
葬儀詳細
葬儀(家の名前): ${row.funeral_owner}
通夜: ${row.funeral_tuya}
葬儀開始時間: ${row.funeral_start}
葬儀終了時間: ${row.funeral_end}
宗派: ${row.funeral_denomination}
`
  //
  //
  //
  const msg_hoyo=`\
------------------------------------------
法要詳細
年忌法要: ${row.hoyo_nenki}
その他: ${row.hoyo_misc}
法要場所: ${row.hoyo_at}
法要(施主名): ${row.hoyo_owner}
法要(施主電話番号): ${row.hoyo_phone}
法要(住所など備考): ${row.hoyo_addr}
予約連絡先: ${row.memo}
`
//
//
//
const msg_hall=`\
------------------------------------------
葬儀詳細
葬儀/法事: ${row.link}
葬儀(家の名前): ${row.funeral_owner}
通夜時間: ${row.funeral_wake}
葬儀/法事時間: ${row.funeral_start}
出棺時間: ${row.coffin_start}
終了時間: ${row.funeral_end}
宗派/導師(寺院): ${row.funeral_memo}
`
//
// 安置 備考欄 2024-1122
//
const msg_anti=`\
------------------------------------------
安置詳細
期間: ${row.link}
葬儀(家の名前): ${row.funeral_owner}
葬儀の予定: ${row.funeral_memo}
終了時間: ${row.funeral_end}
宗派: ${row.funeral_denomination}
備考: ${row.memo}
`

  //
  const kind= row.kind
  const sogi = (kind === '通夜' || kind === '通夜H' ) ? true: false
  const hoyo = kind.startsWith('法要') ? true : false
  //2024 L A and original k
  const hall = kind === '通夜L' ? true: false
  const anti = kind === '通夜A' ? true: false


if(sogi        ){ return msg_base + msg_sogi }
else if(hoyo){ return msg_base + msg_hoyo }
else if(hall  ){ return msg_base + msg_hall}
else if(anti ){ return msg_base + msg_anti}
else { return msg_base + '@@@詳細情報が得られません@@@'} 


  // //
  // // 「詳細」ボタンから windows ダイアログにて 情報を表示するため、など。
  // //
  // const text= tag.startsWith('hoyo') ? (msg_base + msg_hoyo) : (msg_base + msg_sogi)
  // //
  // return text
}

//
// local storage を利用した log utility
// Logio で表示
// kqから emit('notify', {dt, msg}) で来る。Logioではこれを socket.onして捕まえる。
// storageには key '@logcount@' 名にて 件数を書き込んでおく。
// storageには key 'log001' 名にて 文字列(msg)を書き込んでおく。
//
export type NotifyMsg = {
  dts: string; //Date;--> new Date().toISOstring() されてくるのでそのまま保持。
  msg: string;
}
const NotifyKey = '@notifies@'
//
// ● NotifyMsg [] を書いてみる
//
export function notify({dts, msg}: NotifyMsg){
  const notifies = get_notifies()
  notifies.push({dts, msg})
  const json= JSON.stringify(notifies)
  localStorage.setItem(NotifyKey, json)
}
export function get_notifies(): NotifyMsg[]{
  const value= localStorage.getItem(NotifyKey)
  let obj= []
  if( value !== null){
    obj= JSON.parse(value)
  }
  const notifies: NotifyMsg[] = obj as NotifyMsg[]
  return notifies
}
//
// ●remove_
//
export function remove_notifies(){
  localStorage.removeItem(NotifyKey) // removeItem() は void である。
}
//
//
// @2024 cdays を dayform部品までおろして制約を判断する、という ad hoc な改造をためす。
//
//@2024
const fmt = (dt: Date) => format(dt, 'yyyy-MM-dd')

// function anti_row(dt: Date, cdays: ConstraintDay[]): ReservationRow[] {
//   const tuya_a= tag2kind('tuya-a')
//   const sogi_a= tag2kind('sogi-a')
//   const anti_a= tag2kind('anti-a')

//   const found= cdays.find(day => isSameDay( day.dt, dt))
//   if( ! found ){
//     console.log('@@@ NOT FOUND', fmt(dt))
//     return []
//   }
//   const rows: ReservationRow[] = found.lines.filter( line => 
//     line.row.kind === tuya_a ||
//     line.row.kind === sogi_a ||
//     line.row.kind === anti_a
//   ).map( line => line.row)
//   // OK
//   return rows
// }
// //
// // 確認
// //
// function p(rows: ReservationRow[]){
  
//   for (var row of rows){
//     if( row.id === -1 ){
//       console.log(`${fmt(new Date(row.date))} ${row.kind} id none`)
//     }else{
//       console.log(`${fmt(new Date(row.date))} id:${row.id} kind:${row.kind}`)
//       const r= dt_tag(row.link)
//       if( 0<r.length ){
//         console.log(r)
//       }
//     }
//   }
// }
//
// んー、４日予約の途中を指定されたら、だめだ。
//
// export function get_anti_days_rows4(start: string, cdays: ConstraintDay[]){
//   console.log(`@@@get_anti_days_rows4 start ${start}`)
//   const dt0= new Date(start)

//   const r1= anti_row(dt0, cdays)
//   p(r1)

//   const r2= anti_row(addDays(dt0, 1), cdays)
//   p(r2)

//   const r3= anti_row(addDays(dt0, 2), cdays)
//   p(r3)

//   const r4= anti_row(addDays(dt0, 3), cdays)
//   p(r4)
// }

function is_anti(row: ReservationRow){
  const tuya_a= tag2kind('tuya-a')
  const sogi_a= tag2kind('sogi-a')
  const anti_a= tag2kind('anti-a')
  //
  if(
    row.id !== -1 &&
    (row.kind === tuya_a || row.kind === sogi_a || row.kind === anti_a)
  ){
    return true
  }else{
    return false
  }
}
//
// cdays 全scanしなくては。...
// できた result はこの月の 安置のある日 {dt: Date, tag: string}
//  月初月末のことを考えると(cdaysにはたとえばカレンダー始まりの日が安置２日めだったりすることも...)
// なので...
// id があって tagが 安置関係であり row.link が取れない場合も考えると busy daysを集めるためには
// is_anti()にある条件で拾って 重複をなくせばよい。初日が cdaysにふくまれているとはかぎらないのである。
//
// 中日ならとか判断しないといけないので...
//
export function get_anti_days(cdays: ConstraintDay[]): Date[]{
  console.log('@@@get_anti_days')
  const rows: ReservationRow[]=[]
  //
  cdays.forEach( day => {
    const rr= day.lines.map( line => line.row ).filter( row => is_anti(row) )
    rows.push(...rr)
  })

  rows.forEach( row =>
    console.log(`${fmt(new Date(row.date))} id:${row.id} kind:${row.kind} ${row.link}`)
  )
  
  const busy_days= rows.map( row => new Date(row.date))
  
  busy_days.forEach( dt => console.log(`@@@${fmt(dt)}`) )

  return busy_days
}
//
//
//
function get_lines({dt, cdays}:{dt: Date; cdays: ConstraintDay[];}): Line[]{
  const found= cdays.find( c => isSameDay(c.dt, dt) )
  return found ? found.lines : []
}
//
// 指定した日に 安置 の予約があるか
//
export function is_anti_busy({dt, cdays}:{dt: Date; cdays: ConstraintDay[]}): boolean {
  let result: number= 0
  for( const line of get_lines({dt, cdays}) ){
    // 指定日の Line[] について　...
    const r= line.row
    if( is_anti(r) ){
      // ひとつのはずだよね。
      result += 1
      console.log(`@@@id:${r.id} ${r.date} ${r.kind} ${r.link}`)
    }
  }
  return 0<result ? true : false
}
//
// 指定日のSogiOptions3 から呼ばれて、1,2,3,4 日について判断をする。
// 1日がだめならそもそも...というのを {d1: boolean; ...}　で返す。
//
export function is_anti_busy4({dt, cdays}:{dt: Date, cdays: ConstraintDay[]}):
{d1: boolean; d2:boolean; d3:boolean; d4:boolean;} {
  // 1日目
  const d1= is_anti_busy({dt, cdays})
  const d2= is_anti_busy({dt: addDays(dt, 1), cdays})
  const d3= is_anti_busy({dt: addDays(dt, 2), cdays})
  const d4 = is_anti_busy({dt: addDays(dt, 3), cdays})
  
  // １日がだめなら全部busy 部品のdisabledにセットするので
  if(d1) return {d1: true, d2: true, d3: true, d4: true}
  // (1がOKだが)2日めがだめなら 3日 4日もだめ (仮に4日開いてても連続の予約なのでだめ。)
  else if(d2) return {d1: false, d2: true, d3: true, d4: true}
  // (1,2 OK)　３日めがだめなので...
  else if(d3) return {d1: false, d2: false, d3: true, d4: true}
  else if(d4) return {d1: false, d2: false, d3: false, d4: true}
  // 1,2,3,4 がOKなので
  else return {d1: false, d2: false, d3: false, d4: false}
  //
}