import React from 'react'
import {format, addDays, isSameDay} from 'date-fns'
import {Line} from '../utilline'
import {ConstraintDay} from '../caltypes'
import {is_anti_busy4} from '../helpers'

const fmt = (dt: Date) => format(dt, 'yyyy-MM-dd')

//
// kikanに 1, 2, 3, 4日 これに対して 不可能なら trueを。
//
// function kikan_busy({kikan, readonly, date, cdays}:{kikan: number, readonly: boolean; date: string; cdays: ConstraintDay[]}){
//   // 編集モード(上位パーツから指定される) ならもちろん不可能とする 無条件で。
//   if(readonly) return true

//   const dt= new Date(date)
//   //console.log(`@@@kikan_busy kikan:${kikan} @${fmt(dt)}`)
  
//   let result = false // 一応可能としておいて 制約を考える
//   switch(kikan){
//     case 1:
//       result = is_anti_busy({dt, cdays}) >0 ? true: false
//       break
//     case 2:
//       result = is_anti_busy({dt: addDays(dt, 1), cdays}) >0 ? true: false
//       break
//     case 3:
//       result = is_anti_busy({dt: addDays(dt, 2), cdays}) >0 ? true: false
//       break
//     case 4:
//       result = is_anti_busy({dt: addDays(dt, 3), cdays}) >0 ? true: false
//       break
//     default:
//       console.log(`@@@error ${kikan} not supported.`)
//       break
//   }
//   return result
// }


//
// ●2024 安置 A 向け 1,2,3,4日の葬儀期間の選択
// disabled={readonly || line.reason.indexOf('2日')<0} この部分は再考しよう。なぜdisable
// 
// @2024-1216 (はるばる) cdaysを得る。sigh...
//
export const SogiOption3 = ({line, readonly, cdays}: {line: Line; readonly: boolean; cdays: ConstraintDay[]}) => {
  const [option, setOption] = React.useState(line.option)
  const [busy4, setBusy4] = React.useState(is_anti_busy4({dt: new Date(line.row.date), cdays}))
  
  // 今日, ２日め、...
  // const dt1= new Date(line.row.date)
  // const dt2= addDays(dt1, 1)
  // const dt3= addDays(dt1, 2)
  // const dt4= addDays(dt1, 3)
  // const busy_days= get_anti_days(cdays)
  // const n1= busy_days.filter( dt => isSameDay(dt, dt1) ).length
  // const n2= busy_days.filter( dt => isSameDay(dt, dt2) ).length
  // const n3= busy_days.filter( dt => isSameDay(dt, dt3) ).length
  // const n4= busy_days.filter( dt => isSameDay(dt, dt4) ).length
  // console.log(`@@@busy? ${n1}, ${n2}, ${n3}, ${n4}`)

  // function busy(v: string){
  //   let result= []
  //   switch(v){
  //     case '1日':
  //       if(n1) return true
  //       break
  //     case '2日':
  //       if(n1 && n2) return true
  //       break
  //     case '3日':
  //       if(n1 && n2 && n3) return true
  //       break
  //     case '4日':
  //       if(n1 && n2 && n3 && n4) return true
  //       break
  //     default: break
  //   }
  //   return false
  // }


  const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
  //  const disable= busy(e.target.value)
  //  if(disable){
  //    window.alert(`別の予約のため 期間'${e.target.value}' はできません`)
  //    return
  //  }2024-1
    
    // console.log(e.target.value, line.row.date, disable ? '×' : '〇')

    line.option = e.target.value
    setOption(e.target.value)
    
  }

  
  //
  return (<>
  <div className="input-group">
    <label className="input-group-text">期間</label>

    {/* 1日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='1日'} value={'1日'}
        disabled= {readonly || busy4.d1}
        onChange={handle}
      />
      <span className="ms-0">{'1日'}</span>
    </label>

    {/* 2日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='2日'} value={'2日'}
        disabled= {readonly || busy4.d2}
        onChange={handle}
      />
      <span className="ms-0">{'2日'}</span>
    </label>

    {/* 3日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='3日'} value={'3日'}
        disabled= {readonly || busy4.d3}
        onChange={handle}
      />
      <span className="ms-0">{'3日'}</span>
    </label>

    {/* 4日 */}
    <label className="form-control input-group-text pointer">
      <input type="radio" className="form-check-input me-1"
        checked={option==='4日'} value={'4日'}
        disabled= {readonly || busy4.d4}
        onChange={handle}
      />
      <span className="ms-0">{'4日'}</span>
    </label>
  </div>

  </>)
}
